import logo from './logo.svg';
import './App.css';

/* This is a comment */
// this is also a comment
{/* COMMENT within the return function of App */}

function App() {
  return (
    <div className="App">
      <div className="App-header">
      <svg width="100%" height="100%" viewBox='0 0 100 100' xmlns="http://www.w3.org/2000/svg">
  <circle cx="50%" cy="50%" fill="none" r="40" stroke="#ffffff" stroke-width=".05">
    <animate attributeName="r" from="0" to="1000" dur="2s" begin="0s" repeatCount="indefinite"/>
    <animate attributeName="opacity" from="1" to=".5" dur="2s" begin="0s" repeatCount="indefinite"/>
  </circle>
  <circle cx="50%" cy="50%" fill="#ffffff" r=".25"/>
</svg>
  {/*
        <p>
          Ex Nihilo - Coming Soon
          <section>
      {Array.from({ length: 1000 }, (_, i) => <span key={i}><img src={logo} className="App-logo" alt="logo" /></span>)}
 </section>
        </p>
       <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
  */}
  </div>
    </div>
  );
}

export default App;
